(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
if ($('#dashboard').length) {

	void 0;

	if ($('.template-user').length) $templateUser = $('.template-user').clone();
	if ($('.template-users').length) $templateUsers = $('.template-users').clone();

	$(document).on('focus', '#modal-users .modal-body input', function (e) {
		e.stopImmediatePropagation();
		$('#modal-users .modal-body .alert').html('');
		$('#modal-users .modal-body .alert').addClass('hidden');
	});



	window.populateModalUsers = function (siteData) {
		//	Title
		$('#modal-users .modal-title').html('<i class="fas fa-user-friends pr-2"></i>' + siteData.site.name);
		$('#modal-users .modal-body').html('');
		//	Body
		templateUserHtml = '';
		for (i = 0; i < siteData.users.length; i++) {
			$template = $templateUsers.clone();
			$template.find('.username').html(siteData.users[i].username);
			$template.find('.userrole').html(siteData.users[i].userRoleName);
			$template.find('.userdetails').attr('data-userid', siteData.users[i].userID);
			templateUserHtml += $template.html();
		}
		//	Buttons
		btnHtml = '<div class="new-user"><i class="far fa-user-plus mr-2"></i>Add new user</div>';
		//	Populate modal body
		$('#modal-users .modal-body').html(templateUserHtml + btnHtml);
	}

	//	User information for selected site
	window.populateModalUser = function (userid, siteData) {
		void 0

		if (userid == '') {
			//	New user
			void 0
			//	Title
			$('#modal-users .modal-title').html('<i class="fas fa-user-plus pr-2"></i>' + siteData.site.name);
			$('#modal-users .modal-body').html('');
			//	Body
			$template = $templateUser.clone();
			$template.find('#f_userid').remove();
			$template.find('#f_siteid').attr('value', siteData.site.id);
			$template.find('#f_activation').remove();
			$template.find('.f_username').remove();
			$template.find('.f_company').remove();
			$template.find('.f_role_group').remove();
			$template.find('#f_email').attr('value', '');
			//	Buttons
			btnHtml = '<button type="button" id="f_checkemail_submit" class="btn btn-secondary mr-2">CONTINUE</button>';
			$template.find('#form-buttons').html(btnHtml);
			//	Populate modal body
			$('#modal-users .modal-body').html($template.html());

		} else {
			//	Selected user
			void 0
			//	Title
			$('#modal-users .modal-title').html('<i class="fas fa-user pr-2"></i>' + siteData.site.name);
			$('#modal-users .modal-body').html('');
			//	Body
			$template = $templateUser.clone();
			for (i = 0; i < siteData.users.length; i++) {
				if (siteData.users[i].userID == userid) {
					$template.find('#f_userid').attr('value', userid);
					$template.find('#f_siteid').attr('value', siteData.site.id);
					$template.find('#f_username').attr('value', siteData.users[i].username);
					$template.find('#f_company').attr('value', siteData.users[i].userco);
					$template.find('#f_email').attr('value', siteData.users[i].useremail);
					$template.find('.f_role').removeAttr('checked');
					if (siteData.users[i].userRole == 0) {
						$template.find('#f_role0').attr('checked', true);
					} else if (siteData.users[i].userRole == 1) {
						$template.find('#f_role1').attr('checked', true);
					} else {
						$template.find('#f_role2').attr('checked', true);
					}
					//	Button
					btnHtml = '<button type="button" id="f_update_submit" class="btn btn-secondary mr-2">UPDATE</button>';
					btnHtml += '<button type="button" id="f_update_activation_submit" class="btn btn-secondary">UPDATE & SEND ACTIVATION</button>';
					$template.find('#form-buttons').html(btnHtml);
					//	Populate modal body
					$('#modal-users .modal-body').html($template.html());
					break;
				}
			}
		}
	}

	$(document).on('click', '.users-iconqqq', function (e) {
		e.stopImmediatePropagation();
		//	Get siteid
		siteid = $(this).data('siteid');
		//	Get site info
		promiseAjaxGet(window.confUrl + '/lib/site-info/?id=' + siteid).then(function (resp) {
			void 0;
			siteData = resp;
			usersData = resp.users;
			//	Add siteid 
			siteData.site.id = siteid;
			//	Populate Modal users
			populateModalUsers(siteData);
			//	Show Modal users
			$('#modal-dashboard .modal-title').html('<i class="fas fa-user-friends pr-2"></i>' + siteData.site.name);
			$('#modal-dashboard').modal('show');
		});
	});

	window.populateDialog = function (dialogData) {
		//	Dialog menu
		//	if (dialogData.menu) {
		dialogMenuHtml = '';
		for (i = 0; i < dialogData.menu.length; i++) {
			dialogMenuHtml += '<li class="modal-menu-item" data-menu="' + dialogData.menu[i].id + '">' + dialogData.menu[i].name + '</li>'
		}
		$('#modal-dashboard .modal-menu-items').html(dialogMenuHtml);
		//	}
		if (dialogData.content) {
			$('#modal-dashboard .modal-body').html(dialogData.content);
		}

	}

	siteid = 1;
	$(document).on('click', '.users-icon', function (e) {
		e.stopImmediatePropagation();
		//	Get siteid
		siteid = $(this).data('siteid');
		//	Get site info
		promiseAjaxGet(window.confUrl + '/lib/site-info/?id=' + siteid).then(function (resp) {
			void 0;
			siteData = resp.site;
			dialogData = resp.dialog;
			usersData = resp.users;
			void 0
			populateDialog(dialogData)
			$('#modal-dashboard').modal('show');
		});

	});

	$(document).on('click', '#modal-users .userdetails', function (e) {
		e.stopImmediatePropagation();
		populateModalUser($(this).data('userid'), siteData);
	});

	//	-------------------------------------------------------
	//	New user
	//	-------------------------------------------------------

	//	Populate the modal 
	$(document).on('click', '#modal-users .new-user', function (e) {
		e.stopImmediatePropagation();
		void 0;
		void 0;
		populateModalUser('', siteData);
	});

	//	on click check the email 
	$(document).on('click', '#modal-users #f_checkemail_submit', function (e) {
		e.stopImmediatePropagation();
		//	Get the form values
		fObj = $('#modal-users #form_user').serializeArray();
		void 0;
		//	Is the form validated
		validatedForm = false;
		if (validateEmail($('#modal-users #form_user #f_email').val())) {
			void 0;
			promiseAjaxPostData(window.confUrl + '/lib/create-user/', fObj).then(function (resp) {
				void 0;
				if (resp.result == 1) {
					$('#modal-users .modal-body .alert').html('The user already exist for this site')
					$('#modal-users .modal-body .alert').removeClass('hidden');
				} else if (resp.result == 2) {
					siteData.users.push(resp.details);
					void 0;
					populateModalUser(resp.details.userID, siteData);
				}
			});
		} else {
			void 0
		}
	});

	//	On click save
	$(document).on('click', '#modal-users #f_create_submit', function (e) {
		e.stopImmediatePropagation();
		void 0;
		//	Get the form values
		fObj = $('#modal-users #form_user').serializeArray();
		//	Is the form validated
		validatedForm = false;
		if (fObj.length == 7) {
			validatedForm = true;
			for (i = 0; i < fObj.length; i++) {
				if (fObj[i].name == 'f_username' && fObj[i].value == '') validatedForm = false;
				if (fObj[i].name == 'f_email' && fObj[i].value == '') validatedForm = false;
				if (fObj[i].name == 'f_role' && fObj[i].value == '') validatedForm = false;
			}
		}
		if (validatedForm) {
			void 0;
			promiseAjaxPostData(window.confUrl + '/lib/create-user/', fObj).then(function (resp) {
				void 0;
			});
		} else {
			void 0;
		}
	});

	$(document).on('click', '#modal-users #f_update_submit', function (e) {
		e.stopImmediatePropagation();
		fObj = $('#modal-users #form_user').serializeArray();
		void 0
		promiseAjaxPostData(window.confUrl + '/lib/update-user/', fObj).then(function (resp) {
			promiseAjaxGet(window.confUrl + '/lib/site-info/?id=' + siteid).then(function (resp) {
				siteData = resp;
				siteData.site.id = siteid;
				void 0
				//	Populate Modal users
				populateModalUsers(siteData);
			});
		});
	});

	$(document).on('click', '#modal-users #f_update_activation_submit', function (e) {
		e.stopImmediatePropagation();
		$('#modal-users #form_user #f_activation').attr('value', true);
		$('#modal-users #f_update_submit').trigger('click');
	});

	$(document).on('hide.bs.modal', '#modal-users', function (e) {

		if ($('#modal-users #form_user').length) {
			e.preventDefault();
			e.stopPropagation();
			populateModalUsers(siteData);
			//	Show Modal users

			return false;
		}


	})



}



},{}],2:[function(require,module,exports){
if ($('#form-create-account').length) {

	void 0;

	//	Auto-fill
	setTimeout(function () {
		$('input:-webkit-autofill').each(function () {
			$(this).addClass('has-value');
		});
	}, 100);

	$(document).on('focus', '#form-create-account input', function (e) {
		e.stopImmediatePropagation();
		$('#form-create-account .alert').html('');
		$('#form-create-account .alert').addClass('hidden');
		$(this).addClass('has-value');
		if ($(this).val().length > 0) {
			$(this).siblings('.Form-showPassword').addClass('show');
		} else {
			$(this).siblings('.Form-showPassword').removeClass('show');
		}
		$(this).removeClass('is-invalid');

	});

	//	Show or hide password
	$(document).on('click', '#form-create-account .Form-showPassword', function (e) {
		e.stopImmediatePropagation();
		if ($(this).siblings().attr('type') == 'text') {
			$(this).siblings().attr('type', 'password');
			$(this).val('Visa');
		} else {
			$(this).siblings().attr('type', 'text');
			$(this).val('Dölj');
		}
	});

	//	show the button 'Show or hide password'
	$(document).on('keypress', '#form-create-account #f_psw, #form-create-account #f_psw_repeat', function (e) {
		if ($(this).val().length > 0) {
			$(this).siblings('.Form-showPassword').addClass('show');
		} else {
			$(this).siblings('.Form-showPassword').removeClass('show');
		}
	});

	$(document).keypress(function (event) {
		var keycode = (event.keyCode ? event.keyCode : event.which);
		if (keycode == '13') {
			$('#f_create_account_submit').not('.disabled').trigger('click');
		}
	});

	//	Form submit
	$(document).on('click', '#f_create_account_submit:not(.disabled)', function (e) {
		e.stopImmediatePropagation();
		if (validateEmail($('#f_usr').val()) &&
			$('#f_psw').val() == $('#f_psw_repeat').val() &&
			$('#f_psw').val().length > 5) {
			$('#f_create_account_submit').addClass('disabled');
			fObj = $('#f_create_account').serializeArray();
			void 0;
			$('#form-transition').addClass('show');
			promiseAjaxPostData(window.confUrl + '/lib/create-account/', fObj).then(function (resp) {
				void 0;
				$('#form-transition').removeClass('show');
				if (resp.result) {
					$('form').addClass('hidden');
					message = 'Vi har skickat ett e - post för att aktivera ditt konto!';
					$('.alert-success').html(message);
					$('.alert-success').removeClass('hidden');
					$('.alert-danger').addClass('hidden');
				} else {
					if (resp.error == 1) {
						message = 'Det finns redan ett konto med e-post adressen';
					} else if (resp.error == 2) {
						message = 'E-post adressen finns inte registrerade hos maries.se. Kontakta gärna <a href="https://maries.se">kundtjänst</a>';
					}
					$('.alert-danger').html(message);
					$('.alert-danger').removeClass('hidden');
					$('.alert-success').addClass('hidden');
					$('#f_create_account_submit').removeClass('disabled');
				}
			});
		} else {
			if (validateEmail($('#f_usr').val()) == false) {
				message = 'Felaktig e-postadress';
				$('#f_usr').addClass('is-invalid')
			} else if ($('#f_psw').val().length < 6) {
				$('#f_psw').addClass('is-invalid');
				$('#f_psw_repeat').addClass('is-invalid');
				message = 'Ditt lösenord måste innehålla minst sex tecken';
			} else if ($('#f_psw').val() != $('#f_psw_repeat').val()) {
				$('#f_psw').addClass('is-invalid');
				$('#f_psw_repeat').addClass('is-invalid');
				message = 'Lösenorden matchar inte varandran';
			} else {
				$('#f_usr').addClass('is-invalid')
				$('#f_psw').addClass('is-invalid');
				$('#f_psw_repeat').addClass('is-invalid');
				message = 'Felaktig e-postadress eller lösenord';
			}
			$('.alert-danger').html(message);
			$('.alert-danger').removeClass('hidden');
			$('.alert-success').addClass('hidden');
		}
	});

}
},{}],3:[function(require,module,exports){
if ($('#form-forgot-password').length) {

	//	console.log('form-forgot-password.js');

	//	Auto-fill
	setTimeout(function () {
		$('input:-webkit-autofill').each(function () {
			$(this).addClass('has-value');
		});
	}, 100);

	$(document).on('focus', '#form-forgot-password input', function (e) {
		e.stopImmediatePropagation();
		$('#form-forgot-password .alert').html('');
		$('#form-forgot-password .alert').addClass('hidden');
		$(this).addClass('has-value');
	});

	$(document).on('blur', '#form-forgot-password input', function (e) {
		e.stopImmediatePropagation();
		if ($(this).val() == '') $(this).removeClass('has-value');
	});


	$(document).on('click', '#f_forgot_password_submit', function (e) {
		e.stopImmediatePropagation();

		if ($('#f_email').val() != '') {
			fObj = $('#f_forgot_password').serializeArray();
			void 0;
			promiseAjaxPostData(window.confUrl + '/lib/forgot-password/', fObj).then(function (resp) {
				void 0;
				if (resp.response == false) {
					if (resp.error == 3) {
						message = 'Ditt konto är inte aktiverat.<br />';
						message += 'Skicka igen <span id="send-activation" data-id="' + resp.activate + '">aktiveringslänken</span>';
					} else if (resp.error == 4) {
						message = 'Ditt konto är inte aktiverat.<br />';
						message += 'Kontakta gärna <a href="https://www.maries.se/kontakt/for-privatpersoner/">kundtjänst</a>';
					} else {
						message = 'Fel användarnamn eller lösenord';
					}
					$('#form-forgot-password .alert-danger').html(message);
					$('#form-forgot-password .alert-danger').removeClass('hidden');
					$('#form-forgot-password .alert-success').addClass('hidden');
				} else {
					$('#form-forgot-password form').addClass('hidden');
					$('#form-forgot-password .alert-success').html('Vi har mailat din länk för återställning av lösenord!');
					$('#form-forgot-password .alert-success').removeClass('hidden');
				}
			});

		} else {
			$('#form-forgot-password .alert-danger').html('Felaktig e-postadress');
			$('#form-forgot-password .alert-danger').removeClass('hidden');
			$('#form-forgot-password .alert-success').addClass('hidden');
		}
	});

	$(document).on('click', '#send-activation', function (e) {
		e.stopImmediatePropagation();
		activationsLink = $(this).data('id');
		void 0;
		promiseAjaxGet(window.confUrl + '/lib/send-activation/?id=' + activationsLink).then(function (resp) {
			//	console.log(resp)
			if (resp) {
				$('#f_forgot_password').addClass('hidden');
				$('.alert-success').html('Vi har skickat ett e-post för att aktivera ditt konto!');
				$('.alert-success').removeClass('hidden');
				$('.alert-danger').addClass('hidden');
			}
		});
	});

}
},{}],4:[function(require,module,exports){
if ($('#form-login').length) {

	void 0;

	//	Auto-fill
	setTimeout(function () {
		$('input:-webkit-autofill').each(function () {
			$(this).addClass('has-value');
		});
	}, 100);

	$(document).on('focus', '#form-login input', function (e) {
		e.stopImmediatePropagation();
		$('#form-login .alert').html('');
		$('#form-login .alert').addClass('hidden');
		$(this).addClass('has-value');
		if ($('#f_psw').val().length > 0) {
			$('.Form-showPassword').addClass('show');
		} else {
			$('.Form-showPassword').removeClass('show');
		}
	});

	//	Show or hide password
	$(document).on('click', '#form-login .Form-showPassword', function (e) {
		e.stopImmediatePropagation();
		if ($('#f_psw').attr('type') == 'text') {
			$('#f_psw').attr('type', 'password');
			$('.Form-showPassword').val('Visa');
		} else {
			$('#f_psw').attr('type', 'text');
			$('.Form-showPassword').val('Dölj');
		}
	});

	//	show the button 'Show or hide password'
	$(document).on('keypress', '#form-login #f_psw', function (e) {
		if ($(this).val().length > 0) {
			$('.Form-showPassword').addClass('show');
		} else {
			$('.Form-showPassword').removeClass('show');
		}
	});


	$(document).on('blur', '#form-login input', function (e) {
		e.stopImmediatePropagation();
		if ($(this).val() == '') $(this).removeClass('has-value');
		if ($('#f_psw').val().length > 0) {
			$('.Form-showPassword').addClass('show');
		} else {
			$('.Form-showPassword').removeClass('show');
		}
	});

	$(document).keypress(function (event) {
		var keycode = (event.keyCode ? event.keyCode : event.which);
		if (keycode == '13') {
			$('#f_login_submit').not('.disabled').trigger('click');
		}
	});

	//	Form submit
	$(document).on('click', '#f_login_submit:not(.disabled)', function (e) {
		e.stopImmediatePropagation();
		if (validateEmail($('#f_usr').val()) && $('#f_psw').val() != '') {
			//	$('#f_login_submit').addClass('disabled');
			fObj = $('#f_login').serializeArray();
			void 0;
			promiseAjaxPostData(window.confUrl + '/lib/check-login/', fObj).then(function (resp) {
				void 0;
				if (resp.response == false) {
					if (resp.error == 3) {
						message = 'Ditt konto är inte aktiverat.<br />';
						message += 'Skicka igen <span id="send-activation" data-id="' + resp.userInfo.activate + '">aktiveringslänken</span>';
					} else if (resp.error == 4) {
						message = 'Ditt konto är inte aktiverat.<br />';
						message += 'Kontakta gärna <a href="https://www.maries.se/kontakt/for-privatpersoner/">kundtjänst</a>';
					} else {
						message = 'Fel användarnamn eller lösenord';
					}
					$('#form-login .alert-danger').html(message);
					$('#form-login .alert-danger').removeClass('hidden');
					$('#form-login .alert-success').addClass('hidden');
				} else {
					//	show transition section
					$('#form-transition').addClass('show');
					goToUrl = resp.userInfo.site + '/user/' + resp.userInfo.id + '/';
					location.href = goToUrl;
				}
			});
		} else {
			$('#form-login .alert-danger').html('Fel användarnamn eller lösenord');
			$('#form-login .alert-danger').removeClass('hidden');
			$('#form-login .alert-success').addClass('hidden');
		}
	});

	$(document).on('click', '#send-activation', function (e) {
		e.stopImmediatePropagation();
		activationsLink = $(this).data('id');
		void 0;
		promiseAjaxGet(window.confUrl + '/lib/send-activation/?id=' + activationsLink).then(function (resp) {
			void 0
			if (resp) {
				$('#form-login .alert-success').html('Vi har skickat ett e-post för att aktivera ditt konto!');
				$('#form-login .alert-success').removeClass('hidden');
				$('#form-login .alert-danger').addClass('hidden');
			}
		});
	});

}
},{}],5:[function(require,module,exports){
if ($('#form-reset-password').length) {

	void 0;

	//	Auto-fill
	setTimeout(function () {
		$('input:-webkit-autofill').each(function () {
			$(this).addClass('has-value');
		});
	}, 100);

	$(document).on('focus', '#form-reset-password input', function (e) {
		e.stopImmediatePropagation();
		$('#form-reset-password .alert').html('');
		$('#form-reset-password .alert').addClass('hidden');
		$(this).addClass('has-value');
		if ($(this).val().length > 0) {
			$(this).siblings('.Form-showPassword').addClass('show');
		} else {
			$(this).siblings('.Form-showPassword').removeClass('show');
		}
	});

	//	Show or hide password
	$(document).on('click', '#form-reset-password .Form-showPassword', function (e) {
		e.stopImmediatePropagation();
		if ($(this).siblings().attr('type') == 'text') {
			$(this).siblings().attr('type', 'password');
			$(this).val('Visa');
		} else {
			$(this).siblings().attr('type', 'text');
			$(this).val('Dölj');
		}
	});

	//	show the button 'Show or hide password'
	$(document).on('keypress', '#form-reset-password #f_newpsw, #form-reset-password #f_confirmpsw', function (e) {
		if ($(this).val().length > 0) {
			$(this).siblings('.Form-showPassword').addClass('show');
		} else {
			$(this).siblings('.Form-showPassword').removeClass('show');
		}
	});

	$(document).on('click', '#f_reset_password_submit', function (e) {
		e.stopImmediatePropagation();
		if ($('#f_newpsw').val().length > 5) {
			if ($('#f_newpsw').val() == $('#f_confirmpsw').val()) {
				fObj = $('#f_reset_password').serializeArray();
				promiseAjaxPostData(window.confUrl + '/lib/reset-password/', fObj).then(function (resp) {
					if (resp == false) {
						$('#form-reset-password .alert-danger').html('Ditt lösenord är inte giltigt');
						$('#form-reset-password .alert-danger').removeClass('hidden');
					} else {
						$('#form-reset-password form').addClass('hidden');
						$('#form-reset-password .alert-success').html('Du kan nu logga in med ditt nya lösenord');
						$('#form-reset-password .alert-success').removeClass('hidden');
					}
				});
			} else {
				$('#form-reset-password .alert-danger').html('Lösenorden matchar inte varandra');
				$('#form-reset-password .alert-danger').removeClass('hidden');
			}
		} else {
			$('#form-reset-password .alert-danger').html('Ditt lösenord måste innehålla minst sex tecken');
			$('#form-reset-password .alert-danger').removeClass('hidden');
		}

	});

}
},{}],6:[function(require,module,exports){
jQuery(function ($) {

	$ = jQuery;

	void 0;

	//	Config Url
	if ((window.location.href.indexOf("19000") > -1) || (window.location.href.indexOf("localhost") > -1) || (window.location.href.indexOf("19001") > -1)) {
		window.confUrl = 'http://localhost:19001/lcsthlm.co/SubDomains/maries-login/dist';
	} else {
		window.confUrl = 'https://login.maries.se';
	}

	//	----------------------------
	// 	Promises
	//	----------------------------

	//	Get
	window.promiseAjaxGet = function (url) {
		//	console.log(url)
		return new Promise(function (resolve, reject) {
			$.ajax({
				url: url,
				method: 'get',
				dataType: 'json',
				success: function (resp) {
					resolve(resp);
				},
				error: function (XMLHttpRequest, textStatus, errorThrown) {
					reject(new Error("Network error"));
				}
			});
		});
	};

	//	Post
	window.promiseAjaxPostData = function (url, dataObj) {
		//	console.log('promiseAjaxPostData');
		return new Promise(function (resolve, reject) {
			$.ajax({
				url: url,
				type: 'POST',
				data: dataObj,
				dataType: 'json',
				success: function (resp) {
					resolve(resp);
				},
				error: function (XMLHttpRequest, textStatus, errorThrown) {
					reject(new Error("Network error"));
				}
			});
		});
	};

	//	----------------------------
	// 	Validations
	//	----------------------------
	window.validateEmail = function (email) {
		var re = /\S+@\S+\.\S+/;
		return re.test(email);
	}

	$(document).on('click', '.logo', function (e) {
		e.stopImmediatePropagation();
		location.href = 'https://maries.se';
	});

	var form_login = require('../js/form-login.js');
	var form_create_account = require('../js/form-create-account.js');
	var form_forgot_password = require('../js/form-forgot-password.js');
	var form_reset_password = require('../js/form-reset-password.js');
	var dashboard = require('../js/dashboard.js');

});
},{"../js/dashboard.js":1,"../js/form-create-account.js":2,"../js/form-forgot-password.js":3,"../js/form-login.js":4,"../js/form-reset-password.js":5}]},{},[6]);
